import React from "react";
import { Row, Container, Breadcrumb, Card } from "react-bootstrap";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { ChangePassword, isUserSignedIn } from "../../components/accountManagement";
import { SideBarOptions, accountMenuLinks, TopMenuOptions } from "../../components/genericMenu";

const uri = "/account/change-password/";

const ChangePasswordPage = () => {
  const userData = useSelector((state) => state.userData);

  return (
    <Layout
      pageInfo={{ pageName: "changepassword" }}
      sideBarOptions={SideBarOptions(accountMenuLinks, uri, "My Account")}
    >
      <Seo
        title="Change Password"
        keywords={[`change password`, `login`]}
        description="Change Password page"
        url={uri}
      />
      <Container fluid>
        <div id="top-menu">
          <TopMenuOptions menuLinks={accountMenuLinks} currentLink={uri} menuTitle="My Account" />
        </div>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/">Account</Breadcrumb.Item>
            <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        {isUserSignedIn(userData) ? (
          <Row className="d-flex flex-wrap">
            <Card className="change-password-card">
              <Card.Body>
                <ChangePassword email={userData.userName} showLogo={false} />
              </Card.Body>
            </Card>
          </Row>
        ) : (
          <>Not logged in {navigate("/account/signin/")}</>
        )}
      </Container>
    </Layout>
  );
};

export default ChangePasswordPage;
